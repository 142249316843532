import { msalApp, requiresInteraction } from "./auth-utils";
import { CheckUserAuth } from '../Api/WebApi';
import version from '../version.json';
import { AccountInfo } from "@azure/msal-common";

export default class Auth {
  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getCreatorUser = this.getCreatorUser.bind(this);
    this.getCreatorName = this.getCreatorName.bind(this);
    this.getCreatorId = this.getCreatorId.bind(this);
    this.isApprovedCreator = this.isApprovedCreator.bind(this);
    this.isSignedInWithMSA = this.isSignedInWithMSA.bind(this);
  }

  async acquireToken(account: AccountInfo, scopes: string[]): Promise<string> {
      const authenticationResult = await msalApp.acquireTokenSilent({account: account, scopes: scopes}).catch(async error => {
          // Call acquireTokenPopup (popup window) in case of acquireTokenSilent failure
          // due to consent or interaction required ONLY
          if (requiresInteraction(error.errorCode)) {
              return await msalApp.acquireTokenPopup({account: account, scopes: scopes});
          } else {
              console.error('Non-interactive error:', error);
              throw error;
          }
      });

      msalApp.setActiveAccount(authenticationResult.account);

      return authenticationResult.accessToken;
  }

  async onSignIn() {
    console.log("onSignIn() called.");

    const authenticationResult = await msalApp.loginPopup({
      scopes: [
        `api://${process.env.REACT_APP_AZURE_APPLICATION_ID}/Offers.View`
      ]
    }).catch(error => {
      console.log("onSignIn() error:", error);
      throw error;
    });

    msalApp.setActiveAccount(authenticationResult.account);

    if (authenticationResult) {
      let result = await CheckUserAuth(authenticationResult.accessToken);
      
      if (result) {
        localStorage.setItem('api_auth', "true");
        localStorage.setItem('creator_user', result.creatorUser);
        localStorage.setItem('creator_name', result.creatorName);
        localStorage.setItem('creator_short_name', result.creatorShortName);
        localStorage.setItem('creator_id', result.creatorId);
        localStorage.setItem('creator_club', result.creatorClub ? "true" : "false")
        localStorage.setItem('creator_role', result.creatorRole);
        localStorage.setItem('creator_logged_in_version', version.version);

        console.log("onSignIn() successful.");

        return true;
      }
    }

    console.log("onSignIn() failed.");

    return false;
  }

  async login() {
    console.log("login() called.");

    return await this.onSignIn();
  }

  isApprovedCreator() {
    const api_auth = localStorage.getItem('api_auth');

    if (api_auth === "true") {
      return true;
    }

    return false;
  }

  async getAccessToken(): Promise<string> {
    console.log("getAccessToken() called.");
    
    const myAccounts = msalApp.getAllAccounts();
    const tokenResponse = await this.acquireToken(
      myAccounts[0],
      [
        `api://${process.env.REACT_APP_AZURE_APPLICATION_ID}/Offers.View`
      ]
    ).catch(error => {
      console.log("getAccessToken() error:", error);
      throw error;
    });

    return tokenResponse;
  }

  getCreatorUser() {
    const creatorUser = localStorage.getItem('creator_user');
    if (!creatorUser) {
      return "";
    }
    return creatorUser;
  }

  getCreatorName() {
    return localStorage.getItem('creator_name');
  }

  getCreatorShortName() {
    return localStorage.getItem('creator_short_name');
  }

  getCreatorId() {
    return localStorage.getItem('creator_id');
  }

  getCreatorClub() {
    const creator_club = localStorage.getItem('creator_club');

    if (creator_club === "true") {
      return true;
    }

    return false;
  }

  getCreatorRole() {
    return localStorage.getItem('creator_role');
  }

  getCreatorLoggedInVersion() {
    return localStorage.getItem('creator_logged_in_version');
  }

  logout() {
    msalApp.logoutRedirect();

    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('api_auth');
    localStorage.removeItem('creator_user');
    localStorage.removeItem('creator_name');
    localStorage.removeItem('creator_short_name');
    localStorage.removeItem('creator_club');
    localStorage.removeItem('creator_role');
    localStorage.removeItem('creator_logged_in_version');
  }

  isSignedInWithMSA() {
    return msalApp.getActiveAccount() !== null;
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    var isApprovedCreator = this.isApprovedCreator();
    var isCurrent = this.isSignedInWithMSA();
    if (isApprovedCreator && isCurrent) {
      return true;
    } else {
      return false;
    }
  }
}
