import React, { useEffect, useState } from 'react';
import { FormControl, Badge, InputGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

interface Props {
    tagValues?: string[];
    placeholder?: string;
    returnCallback?: any;
    options?: Tag[]
    disabled?: boolean;
}

export interface Tag {
    key: string;
    value: string;
}

function TagInput(props: Props) {
    const [tags, setTags] = useState<Tag[]>([]);
    const [inputValue, setInputValue] = useState<string>('');
    const [warning, setWarning] = useState<any>();

    const handleInputChange = ((e: any) => {
        setInputValue(e.target.value);
    });

    const handleInputKeyDown = ((e: any) => {
        if (e.key === 'Enter' && inputValue.trim() !== '') {
            if (props.options) {
                const tag = props.options.find(o => o.value === inputValue.trim());
                if (tag) {
                    const newTags = [...tags, tag];
                    setWarning(null);
                    setTags(newTags);

                    if (props.returnCallback) {
                        props.returnCallback(newTags.map(tag => tag.key));
                    }
                } else {
                    setWarning(`${inputValue} is not a valid Partner Short Name. Try a different one.`)
                }
            } else {
                const newTags = [...tags, { key: inputValue, value: inputValue }];
                setWarning(null);
                setTags(newTags);

                if (props.returnCallback) {
                    props.returnCallback(newTags.map(tag => tag.key));
                }
            }
            setInputValue('');
        }
    });

    const handleTagRemove = ((tagToRemove: Tag) => {
        const newTags = tags.filter((tag) => tag !== tagToRemove);
        setTags(newTags);
        props.returnCallback(newTags.map(tag => tag.key));
    });

    useEffect(() => {
        if (!props.tagValues) {
            setTags([]);
        } else if (!props.options) {
            setTags(props.tagValues.map(tag => ({ key: tag, value: tag })));    
        } else {
            setTags(props.options.filter(o => props.tagValues?.includes(o.key)));
        }
    }, [props.tagValues, props.options]);

    return (
        <InputGroup className="tags-container" size='sm'>
            {tags.length > 0 && <InputGroup.Text>
                {tags.map((tag, index) => (
                    <Badge
                        key={index}
                        className="tag-badge pill"
                        bg="primary"
                        pill={true}
                    >
                        {tag.value}
                        {!props.disabled && <FontAwesomeIcon icon={faXmark} className="icon" onClick={() => handleTagRemove(tag)}/>}
                    </Badge>
                ))}
            </InputGroup.Text>}
            <FormControl
                type="text"
                size="sm"
                placeholder={props.placeholder}
                disabled={props.disabled}
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleInputKeyDown}
            />
        </InputGroup>
    );
}

export default TagInput;