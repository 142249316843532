import React, { useState } from 'react';
import Auth from "../Auth/Auth";
import { Button, FormCheck, Modal } from 'react-bootstrap';
import { FileFormFieldCustom, ValidationFormField, ValidationReport } from './FormFields/FormField';
import { MAX_UPLOAD_SIZE, VALIDATION_DECISIONS } from '../Constants/enums';
import { ContentPackage } from '../Models/AugerOffers/ContentPackage';
import { MarketplaceItem } from '../Models/AugerOffers/MarketplaceItem';

interface Props {
    auth: Auth,
    show: boolean,
    isNew: boolean,
    contentPackage: ContentPackage,
    processFormInfo: (contentPackage: ContentPackage| undefined, propertyName: keyof ContentPackage, propertyValue?: any) => void,
    onSave: () => void,
    onHide: () => void
}

export function ContentPackageForm(props: Props) {
    const [binaryFile, setBinaryFile] = useState<File | undefined>();
    const [ingestPC, setIngestPC] = useState(true);
    const [ingestXbox, setIngestXbox] = useState(true);
    const [validationResult, setValidationResult] = useState<ValidationReport | undefined>();
    const sizeInMb = MAX_UPLOAD_SIZE / 1024 / 1024;

    function processFormInfo(contentPackage: ContentPackage | undefined, propertyName: keyof ContentPackage, propertyValue?: any) {
        if (propertyName as string === 'validationResult') {
            setValidationResult(propertyValue as ValidationReport);
        } else if (propertyName as string === 'binaryFile') {
            setBinaryFile(propertyValue as File);
        } else {
            contentPackage?.setFieldFromName(propertyName as keyof MarketplaceItem, propertyValue);

            props.processFormInfo(contentPackage, propertyName, propertyValue);
        }
    }

    return (
        <Modal show={props.show} centered onHide={() => props.onHide()} backdrop={"static"} keyboard={false}>
            <Modal.Body>
                <div style={{textAlign: "center", marginBottom: "25px"}}>
                    <FormCheck
                        key={"PC"}
                        label={"PC"}
                        checked={ingestPC}
                        onChange={() => { setIngestPC(!ingestPC); processFormInfo(props.contentPackage, "publishingTarget" as keyof ContentPackage, !ingestPC && ingestXbox ? "PCAndXbox" : ingestXbox ? "Xbox" : "PC") }}
                        inline/>
                    <FormCheck
                        key={"Xbox"}
                        label={"Xbox"}
                        checked={ingestXbox}
                        onChange={() => { setIngestXbox(!ingestXbox); processFormInfo(props.contentPackage, "publishingTarget" as keyof ContentPackage, ingestPC && !ingestXbox ? "PCAndXbox" : ingestPC ? "PC" : "Xbox") }}
                        inline/>
                </div>
                {binaryFile ?
                    <ValidationFormField
                        key={"validationResult"}
                        game={"fs24"}
                        context={props.contentPackage}
                        placeholder={""}
                        helpContent={`Your content must pass validation in order to submit.`}
                        propertyName={"validationResult"}
                        isNew={true}
                        auth={props.auth}
                        binaryFile={binaryFile}
                        returnCallback={processFormInfo}
                        isAnUpdate={false}
                        offerId={""}
                        offerType={"CIDS_OFFER"} /> :
                    <FileFormFieldCustom
                        key={"binaryFile"}
                        context={props.contentPackage}
                        helpContent={`Upload a zip with a maximum file size of ${sizeInMb} MB`}
                        propertyName={"binaryFile"}
                        isNew={true}
                        returnCallback={processFormInfo} />}
                <div style={{textAlign: "center"}}>
                    <Button disabled={!validationResult || validationResult?.decision === VALIDATION_DECISIONS.PENDING} style={{ margin: 10, width: 100 }} variant="default" onClick={() => props.onSave()}>{"Save"}</Button>
                    <Button style={{ margin: 10, width: 100 }} variant="default" onClick={() => props.onHide()}>{"Cancel"}</Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}
