import React from 'react';
import { Card } from 'react-bootstrap';
import Auth from '../Auth/Auth'
import { MarketplacePackage } from '../Models/AugerOffers/MarketplacePackage';
import { StatusBar } from './StatusBar';
import { MarketplaceSummary, PackageDetails, PlatformDetails } from '../Models/AugerOffers/MarketplaceDetails';
import { ContentPackage } from '../Models/AugerOffers/ContentPackage';
import { ContentPackageBrowserDetails } from './ContentPackageBrowserDetails';
import { MarketplaceProductForm } from './MarketplaceProductForm';

interface Props {
    auth: Auth,
    isNew: boolean,
    onSave: () => Promise<void>,
    onCancel: () => void,
    processFormInfo: (marketplacePackage: MarketplacePackage | undefined, propertyName: keyof MarketplacePackage, propertyValue?: any) => void,
    marketplacePackage: MarketplacePackage,
    marketplacePackagesDetails: PackageDetails<MarketplaceSummary>[],
    contentPackage: ContentPackage,
    contentPackagesDetails: PackageDetails<PlatformDetails>[]
}

export function MarketplaceProductDetails(props: Props) {
    return (
        <div className="container create-new">
            <div className="row">
                <main className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    {false && <Card style={{ marginTop: "40px" }}>
                        <MarketplaceProductForm auth={props.auth} isNew={props.isNew} validate={false} onSave={props.onSave} onCancel={props.onCancel} processFormInfo={props.processFormInfo} marketplacePackage={props.marketplacePackage} marketplacePackagesDetails={props.marketplacePackagesDetails} contentPackagesDetails={props.contentPackagesDetails} />
                    </Card>}
                    {props.contentPackage && <Card style={{ marginTop: "40px" }}>
                        <ContentPackageBrowserDetails contentPackage={props.contentPackage} />
                    </Card>}
                    <Card style={{ marginBottom: "40px", marginTop: "40px" }}>
                        <h4>Microsoft Status</h4>
                        <StatusBar cardState={"Content In Testing"} offerType={props.marketplacePackage.offerType.value} />
                    </Card>
                </main>
            </div>
        </div>
    );
}
