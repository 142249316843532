import axios from 'axios';
import { MAX_UPLOAD_SIZE } from '../Constants/enums';
import { GetWebApiUrl, makeCancelable } from '../Constants/utils';
import { uploadBrowserData } from './uploadBrowserData';
const API_URL = GetWebApiUrl();

const augerOfferTypeHeaderName = "Auger-OfferType";

export const GetAllSubmissions = (auth, offerType, releaseOwner = "all") => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then(accessToken => {
                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        [augerOfferTypeHeaderName]: offerType
                    }
                }

                axios.get(`${API_URL}/submissions/${offerType}/${releaseOwner}`, options).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            }).catch(error => {
                reject(error);
            });
        }
    );
}

export const GetSubmission = (auth, id) => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then(accessToken => {
                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }

                axios.get(`${API_URL}/submission/${id}`, options).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            }).catch(error => {
                reject(error);
            });
        }
    );
}

export const GetAttachments = (auth, offerType, binaryPath) => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then(accessToken => {
                const data = {
                    filePath: binaryPath
                };

                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        [augerOfferTypeHeaderName]: offerType
                    }
                }

                axios.post(`${API_URL}/attachments`, data, options).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            }).catch(error => {
                reject(error);
            });
        }
    );
}

export const GetReleaseOwners = (auth) => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then(accessToken => {
                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }

                axios.get(`${API_URL}/releaseowners`, options).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            }).catch(error => {
                reject(error);
            });
        }
    );
}

export const GetDefaultModel = (auth, offerType) => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then(accessToken => {
                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        [augerOfferTypeHeaderName]: offerType
                    }
                }

                axios.get(`${API_URL}/defaultModel`, options).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            }).catch(error => {
                reject(error);
            });
        }
    );
}

export const GetReport = (auth, id) => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then(accessToken => {
                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }

                axios.get(`${API_URL}/report/${id}`, options).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            }).catch(error => {
                reject(error);
            });
        }
    );
}

export const GetValidationReport = (auth, game, id) => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then(accessToken => {
                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }

                axios.get(`${API_URL}/validation/${game}/report/${id}`, options).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            }).catch(error => {
                reject(error);
            });
        }
    );
}

export const GetValidationReportSummary = (auth) => {
    return makeCancelable(new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then(accessToken => {
                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }

                axios.get(`${API_URL}/validation/fs20/summary`, options).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            }).catch(error => {
                reject(error);
            });
        }
    ));
}

export const GetUser = (auth) => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then(accessToken => {
                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }

                axios.get(`${API_URL}/user`, options).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            }).catch(error => {
                reject(error);
            });
        }
    );
}

export const SetShipDate = (auth, id, shipDate, offerType) => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then(accessToken => {
                const options = {
                    headers: {
                        ContentType: `application/x-www-form-urlencoded`,
                        Authorization: `Bearer ${accessToken}`,
                        OfferId: id,
                        [augerOfferTypeHeaderName]: offerType
                    }
                }

                axios.post(`${API_URL}/submission/${id}/approve`, shipDate, options).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            }).catch(error => {
                console.log("error: ", error);
                reject(error);
            });
        }
    );
}

export const MoveFile = (auth, validationId, binaryPath, offerType) => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then(accessToken => {
                const data = {
                    fileId: validationId,
                    filePath: binaryPath
                };

                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        [augerOfferTypeHeaderName]: offerType
                    }
                }

                axios.post(`${API_URL}/moveFile`, data, options).then(response => {
                    // console.log("Publish Date : ", response);
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            }).catch(error => {
                console.log("error: ", error);
                reject(error);
            });
        }
    );
}

export const UploadFile = (auth, file, fileName, progressCallback, uri, id) => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then(accessToken => {
                /// alert("UploadFile " + fileName + "(1) size: " + file.size + " name(2): " + file.name )
                if (file.size < 1){
                    reject("file " + file.name + " cannot be uploaded to " + fileName + " (file size: " + file.size + ")")
                }
                if (file.size > MAX_UPLOAD_SIZE) {
                    reject("File " + file.name + " is larger than " + MAX_UPLOAD_SIZE / (1024 * 1024) + "MB!");
                }

                const data = {
                    filename: fileName,
                    id: id
                }

                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }

                axios.post(`${API_URL}/${uri}`, data, options).then(response => {
                    const url = response.data.sasUri;

                    uploadBrowserData(url, file, function (ev) {
                        var progress = (ev.loadedBytes * 100) / file.size;
                        if (progressCallback) {
                            progressCallback(progress);
                        }
                    }, function () {
                        resolve();
                    }, function (error) {
                        reject(error);
                    });
                }).catch(error => {
                    console.log("error:", error);
                    reject(error);
                });
            }).catch(error => {
                reject(error);
            });
        }
    );
}

export const GetBlobSasUri = (auth, filename, id) => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then(accessToken => {
                const data = {
                    filename: filename,
                    id: id
                }

                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }

                axios.post(`${API_URL}/downloadUri`, data, options).then(response => {
                    resolve(response.data.sasUri);
                }).catch(error => {
                    reject(error);
                });
            }).catch(error => {
                reject(error);
            });
        }
    );
}

export const GetPackageFile = (auth, game, packageName, filename) => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then(accessToken => {
                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }

                axios.get(`${API_URL}/validation/${game}/file/${packageName}/${filename}`, options).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            }).catch(error => {
                reject(error);
            });
        }
    );
}

export const GetPackageFileName = (auth, game, packageName, filename) => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then(accessToken => {
                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }

                axios.get(`${API_URL}/validation/${game}/filename/${packageName}/${filename}`, options).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            }).catch(error => {
                reject(error);
            });
        }
    );
}

export const GetPackageFileInfo = (auth, game, packageName, filename) => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then(accessToken => {
                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }

                axios.head(`${API_URL}/validation/${game}/file/${packageName}/${filename}`, options).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            }).catch(error => {
                reject(error);
            });
        }
    );
}

export const GetCreatorName = (auth, creatorShortName) => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then(accessToken => {
                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }

                axios.get(`${API_URL}/name/${creatorShortName}`, options).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            }).catch(error => {
                reject(error);
            });
        }
    );
}

export const CheckUserAuth = (accessToken) => {
    console.log("CheckUserAuth() called.");

    return new Promise(
        (resolve, reject) => {
            const options = {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            }

            axios.get(`${API_URL}/CheckAuth`, options).then(response => {
                console.log("CheckUserAuth() response: ", response);
                resolve(response.data);
            }).catch(error => {
                console.log("CheckUserAuth() error: ", error);
                reject(error);
            });
        }
    );
}

export const SubmitMetaData = (auth, updateInfo) => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then(accessToken => {
                console.log("Submitting update with this info", updateInfo);

                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "application/json",
                        [augerOfferTypeHeaderName]: updateInfo.offerType
                    }
                }

                if (updateInfo.cardId) {
                    axios.put(`${API_URL}/submission/create`, updateInfo, options).then(response => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error);
                    });
                } else {
                    axios.post(`${API_URL}/submission/create`, updateInfo, options).then(response => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error);
                    });
                }
            }).catch(error => {
                reject(error);
            });
        }
    );
}

export const UpdateMetaData = (auth, updateInfo) => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then(accessToken => {
                console.log("Submitting update with this info", updateInfo);

                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "application/json"
                    }
                }

                if (updateInfo.cardId) {
                    axios.patch(`${API_URL}/submission/update/${updateInfo.cardId}`, updateInfo, options).then(response => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error);
                    });
                }
            }).catch(error => {
                reject(error);
            });
        }
    );
}

export const IngestSubmission = (auth, updateInfo) => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then(accessToken => {
                console.log("Submitting update with this info", updateInfo);

                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "application/json"
                    }
                }

                if (updateInfo.cardId) {
                    axios.put(`${API_URL}/submission/ingest`, updateInfo, options).then(response => {
                        resolve(response.data);
                    })
                } else {
                    axios.post(`${API_URL}/submission/ingest`, updateInfo, options).then(response => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error);
                    });
                }
            }).catch(error => {
                reject(error);
            });
        }
    );
}

export const GetPipelineRun = (auth, pipelineRunId) => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then(accessToken => {
                console.log("Retrieving run #", pipelineRunId);

                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "application/json"
                    }
                }

                axios.get(`${API_URL}/submission/runs/${pipelineRunId}`, options).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            }).catch(error => {
                reject(error);
            });
        }
    );
}

export const SendValidationQueueMessage = (auth, game, filename, packageName, id) => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then(accessToken => {
                const data = {
                    filename: filename,
                    packageName: packageName,
                    submissionId: id
                }

                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "application/json"
                    }
                }

                axios.post(`${API_URL}/validation/${game}/queue`, data, options).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            }).catch(error => {
                reject(error);
            });
        }
    );
}

export const TakedownOffer = (auth, offerId, offerType) => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then(accessToken => {
                const options = {
                    headers: {
                        ContentType: `application/x-www-form-urlencoded`,
                        Authorization: `Bearer ${accessToken}`,
                        [augerOfferTypeHeaderName]: offerType
                    }
                }

                axios.put(`${API_URL}/submission/${offerId}/takedown`, null, options).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            }).catch(error => {
                console.log("error: ", error);
                reject(error);
            });
        }
    );
}

export const GetPackageVersionData = (auth, packageName, refresh) => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then(accessToken => {
                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    }
                };

                axios.get(`${API_URL}/packageVersion/${packageName}?refresh=${refresh}`, options).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    console.log("error: ", error);
                    reject(error);
                });
            }).catch(error => {
                console.log("error: ", error);
                reject(error);
            });
        }
    );
}

export const getUserV2 = (auth, userId = "all") => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then(accessToken => {
                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }

                axios.get(`${API_URL}/user/${userId}`, options).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    console.log(error);
                    reject(error);
                })
            }).catch(error => {
                console.log("error: ", error);
                reject(error);
            })
        }
    );
}

export const createUserV2 = (auth, user) => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then(accessToken => {
                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }

                axios.post(`${API_URL}/user/create`, user, options).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    console.log(error);
                    reject(error);
                })
            }).catch(error => {
                console.log("error: ", error);
                reject(error);
            })
        }
    );
}

export const updateUserV2 = (auth, user) => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then(accessToken => {
                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }

                axios.post(`${API_URL}/user/update`, user, options).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    console.log(error);
                    reject(error);
                })
            }).catch(error => {
                console.log("error: ", error);
                reject(error);
            })
        }
    );
}

export const getPartnerV2 = (auth, partnerId = "all") => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then(accessToken => {
                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }

                axios.get(`${API_URL}/partner/${partnerId}`, options).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    console.log(error);
                    reject(error);
                })
            }).catch(error => {
                console.log("error: ", error);
                reject(error);
            })
        }
    );
}

export const createPartnerV2 = (auth, partner) => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then(accessToken => {
                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }

                axios.post(`${API_URL}/partner/create`, partner, options).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    console.log(error);
                    reject(error);
                })
            }).catch(error => {
                console.log("error: ", error);
                reject(error);
            })
        }
    );
}

export const updatePartnerV2 = (auth, partner) => {
    return new Promise(
        (resolve, reject) => {
            auth.getAccessToken().then(accessToken => {
                const options = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }

                axios.post(`${API_URL}/partner/update`, partner, options).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    console.log(error);
                    reject(error);
                })
            }).catch(error => {
                console.log("error: ", error);
                reject(error);
            })
        }
    );
}
