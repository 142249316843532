import { PackTypes } from '../Models/AugerOffers/DurableOfferModel';
import  { Component } from 'react';
import { UploadFile, GetPackageFile, GetPackageFileInfo, GetCreatorName, SendValidationQueueMessage, GetPackageFileName } from '../Api/WebApi';
import { UPDATE_STATUS } from '../Constants/enums';
import { VersionCompare } from '../Constants/utils';

const { v4: uuidv4 } = require('uuid');

class ValidationUploader extends Component {
    constructor(props) {
        super(props);

        this.state = this.initialState;
        this.handleBinaryProgress = this.handleBinaryProgress.bind(this);
        this.startUpload = this.startUpload.bind(this);
        this.restartValidation = this.restartValidation.bind(this);
        this.setOfferDetails = this.setOfferDetails.bind(this);
    }

    get initialState() {
        return {
            auth: this.props.auth,
            binaryFile: this.props.binaryFile,
            binaryStatus: UPDATE_STATUS.notstarted,
            queueStatus: UPDATE_STATUS.notstarted,
            overallStatus: UPDATE_STATUS.pending,
            uuid: uuidv4(),
            uploadProgress : 0,
            show: true,
            offerType: this.props.offerType,
            isAnUpdate:this.props.isAnUpdate,
            error: ""
        }
    }

    async componentDidMount(){
        await this.startUpload();
    }

    async startUpload(){
        const {
            binaryFile,
            auth,
            uuid
        } = this.state;

        if(!binaryFile){
            //console.log("no binary file!")
            return;
        }
        //console.log("uploading!");
        
        this.setState(() => ({ binaryStatus: UPDATE_STATUS.pending }));
        this.setState(() => ({ overallStatus: UPDATE_STATUS.pending }));

        this.props.statusCallback(UPDATE_STATUS.pending, uuid);

        await UploadFile(auth, binaryFile, uuid, this.handleBinaryProgress, `validation/${this.props.game}/uploadTemp`).catch((error) => {
            console.log(error);
            this.setState(() => ({ binaryStatus: UPDATE_STATUS.failed, error: error.message }));
            return;
        });
 
        if (this.props.game === 'fs20') {
            let wasmFile = await GetPackageFileInfo(auth, this.props.game, uuid, '*.wasm').catch((error) => {
                this.props.reportCallback(this.props.offer, "wasmRequired", "No");
            });

            if (wasmFile || wasmFile === '') {
                this.props.reportCallback(this.props.offer, "wasmRequired", "Yes");
            }
            
            let contentManifest = await GetPackageFile(auth, this.props.game, uuid, 'ContentManifest.json').catch((error) => {
                throw new Error("Error retrieving ContentManifest.json: " + error.message);
            });

            if (contentManifest.publishingGroups && contentManifest.publishingGroups.length !== 0) {
                if (contentManifest.publishingGroups.length !== 1) {
                    throw new Error("Submissions with more than one bundle or pack are not currently supported. Please contact your content manager for further information.");
                }

                for (let publishingGroup of contentManifest.publishingGroups) {
                    let publishingGroupJsonFileName = `PublishingGroupsContent/${publishingGroup.name}.json`;

                    let publishingGroupJson = await GetPackageFile(auth, this.props.game, uuid, publishingGroupJsonFileName).catch((error) => {
                        throw new Error("Error retrieving " + publishingGroupJsonFileName + ": " + error.message);
                    });

                    let manifest = {
                        content_type: publishingGroupJson.type === "Bundle" ? "BUNDLE" : "PACK",
                        minimum_game_version: "0.0.0",
                        title: publishingGroupJson.title
                    }

                    let packageJsonFileName = `PublishingGroupsMetadata/${publishingGroup.name}/${publishingGroup.name}.json`;
                    let marketplaceJsonFileName = `PublishingGroupsMetadata/${publishingGroup.name}/MarketplaceData/${publishingGroup.name}/Marketplace.json`;

                    let packageJson = await GetPackageFile(auth, this.props.game, uuid, packageJsonFileName).catch((error) => {
                        throw new Error("Error retrieving " + packageJsonFileName + ": " + error.message);
                    });

                    let marketplaceJson = await GetPackageFile(auth, this.props.game, uuid, marketplaceJsonFileName).catch((error) => {
                        throw new Error("Error retrieving " + marketplaceJsonFileName + ": " + error.message);
                    });

                    if (manifest.content_type === "BUNDLE") {
                        if (contentManifest.packages && contentManifest.packages.length !== 0) {
                            throw new Error("Dynamic Bundles with embedded content packages are net yet supported. Please contact your content manager for further information.");
                        }
                    } else {
                        if (!contentManifest.packages || contentManifest.packages.length === 0) {
                            throw new Error("Packs must include content packages. Please contact your content manager for further information.");
                        }

                        for (let publishingPackage of contentManifest.packages) {
                            let manifestJsonFileName = `PackagesContent/${publishingPackage.name}/manifest.json`;

                            let manifestJson = await GetPackageFile(auth, this.props.game, uuid, manifestJsonFileName).catch((error) => {
                                throw new Error("Error retrieving " + manifestJsonFileName + ": " + error.message);
                            });

                            if (VersionCompare(manifest.minimum_game_version, manifestJson.minimum_game_version) > 0) {
                                manifest.minimum_game_version = manifestJson.minimum_game_version;
                            }
                        }
                    }
                    
                    this.setOfferDetails(auth, publishingGroup, packageJson, marketplaceJson, manifest, uuid);
                }
            
                this.setState(() => ({ binaryStatus: UPDATE_STATUS.success, packageName: contentManifest.publishingGroups[0].name }));
            }

            else if (contentManifest.packages && contentManifest.packages.length !== 0) {
                if (contentManifest.packages.length !== 1) {
                    throw new Error("Submissions with more than one package are not currently supported. Please contact your content manager for further information.");
                }

                for (let publishingPackage of contentManifest.packages) {
                    let packageJsonFileName = `PackagesMetadata/${publishingPackage.name}/${publishingPackage.name}.json`;
                    let marketplaceJsonFileName = `PackagesMetadata/${publishingPackage.name}/MarketplaceData/${publishingPackage.name}/Marketplace.json`;
                    let manifestJsonFileName = `PackagesContent/${publishingPackage.name}/manifest.json`;

                    let packageJson = await GetPackageFile(auth, this.props.game, uuid, packageJsonFileName).catch((error) => {
                        throw new Error("Error retrieving " + packageJsonFileName + ": " + error.message);
                    });

                    let marketplaceJson = await GetPackageFile(auth, this.props.game, uuid, marketplaceJsonFileName).catch((error) => {
                        throw new Error("Error retrieving " + marketplaceJsonFileName + ": " + error.message);
                    });

                    let manifestJson = await GetPackageFile(auth, this.props.game, uuid, manifestJsonFileName).catch((error) => {
                        throw new Error("Error retrieving " + manifestJsonFileName + ": " + error.message);
                    });

                    this.setOfferDetails(auth, publishingPackage, packageJson, marketplaceJson, manifestJson, uuid);
                }
                
                this.setState(() => ({ binaryStatus: UPDATE_STATUS.success, packageName: contentManifest.packages[0].name }));
            }
        } else {
            let manifestJsonFileName = await GetPackageFileName(auth, this.props.game, uuid, '*/manifest.json').catch((error) => {
                throw new Error("Error retrieving manifest.json: " + error.message);
            });

            let packageName = manifestJsonFileName.split('/')[0];

            if (packageName === 'PackagesContent') {
                packageName = manifestJsonFileName.split('/')[1];
            }

            let manifestJson = await GetPackageFile(auth, this.props.game, uuid, '*/manifest.json').catch((error) => {
                throw new Error("Error retrieving manifest.json: " + error.message);
            });

            this.props.reportCallback(this.props.offer, "packageName", packageName);
            this.props.reportCallback(this.props.offer, "title", manifestJson.title);
            this.props.reportCallback(this.props.offer, "packageContentType", manifestJson.content_type);
            this.props.reportCallback(this.props.offer, "submissionId", uuid);

            this.setState(() => ({ binaryStatus: UPDATE_STATUS.success, packageName: packageName }));
        }
    }

    setOfferDetails(auth, publishingItem, packageJson, marketplaceJson, manifest, uuid) {
        this.props.reportCallback(this.props.offer, "ingestionPlatforms", publishingItem.platforms.join(","));
        this.props.reportCallback(this.props.offer, "packageName", publishingItem.name);

        this.props.reportCallback(this.props.offer, "submittedVersion", manifest?.package_version ?? "N/A");
        this.props.reportCallback(this.props.offer, "minimumGameVersion", manifest?.minimum_game_version ?? "N/A");
        this.props.reportCallback(this.props.offer, "manifestTitle", manifest?.title);
        this.props.reportCallback(this.props.offer, "offerTitle", marketplaceJson["market-data"].Neutral.title);
        this.props.reportCallback(this.props.offer, "marketplaceCreator", marketplaceJson["market-data"].Neutral.creator);

        let pack_type = PackTypes[manifest.content_type];

        let category = packageJson.marketplace.sections[0].category;

        this.props.reportCallback(this.props.offer, "contentCategory", category);
        this.props.reportCallback(this.props.offer, "packType", pack_type === PackTypes.SCENERY && category?.toLowerCase().includes("airport") ? "Airport" : pack_type);
        this.props.reportCallback(this.props.offer, "creatorId", packageJson.business.AccountSellerId);
        this.props.reportCallback(this.props.offer, "shortCreatorName", packageJson.business.ThirdPartyShortName);
        this.props.reportCallback(this.props.offer, "offerPrice", parseFloat(packageJson.business.PriceInUSD).toFixed(2));

        if (auth.getCreatorId() === '00000000') {
            GetCreatorName(auth, packageJson.business.ThirdPartyShortName).then(seller => {
                this.props.reportCallback(this.props.offer, "creatorName", seller.creatorName);
            }).catch((error) => {
                console.log("Error retrieving creator name.", error);
            })
        }
    }

    restartValidation(){
        //this.setState(() => ({ ...this.initialState }));
        //this.startUpload();
    }

    async componentDidUpdate(prevProps, prevState) {
        if(prevProps.binaryFile !== this.props.binaryFile){
            this.setState(() => ({ ...this.initialState }));
            return;
        }
        if(prevState.binaryFile !== this.state.binaryFile){
            await this.startUpload();
            return;
        }

        const {
            binaryStatus,
            overallStatus,
            queueStatus,
            uuid
        } = this.state;

        var newOverallStatus = overallStatus;
        if (binaryStatus === UPDATE_STATUS.success &&
            queueStatus === UPDATE_STATUS.success) {
            newOverallStatus = UPDATE_STATUS.success;
        }
        if (binaryStatus === UPDATE_STATUS.failed ||
            queueStatus === UPDATE_STATUS.failed) {
            newOverallStatus = UPDATE_STATUS.failed;
        }
        if (newOverallStatus !== overallStatus) {
            this.setState(() => ({ overallStatus: newOverallStatus }));
            this.props.statusCallback(newOverallStatus, uuid, this.state.error);
            //console.log("statusCallback", newOverallStatus);
        }

        if (binaryStatus === UPDATE_STATUS.success &&
            queueStatus === UPDATE_STATUS.notstarted) {
            this.setState(() => ({ queueStatus: UPDATE_STATUS.pending }));
            this.queueValidation();
        }

    }

    componentWillUnmount(){
        console.log("Unmounting ValidationUploader!");
    }

    queueValidation() {
        const {
            auth,
            binaryFile,
            packageName,
            uuid,
            offerType,
            isAnUpdate
        } = this.state;
        //console.log("queueingValidation");

        if(!binaryFile || !uuid){
            return;
        }

        SendValidationQueueMessage(auth, this.props.game, binaryFile.name, packageName, uuid).then((result) => {
            //console.log("validationQueue", result);
            this.setState(() => ({ queueStatus: UPDATE_STATUS.success }));
        }).catch((error) => {
            //console.log("validationQueue", error);
            this.setState(() => ({ queueStatus: UPDATE_STATUS.failed }));
        })
    }

    resetToDefault() {
        this.setState(() => ({ value: this.state.default }));
    }

    handleBinaryProgress(percentage){

        if(percentage < this.state.uploadProgress){
            return;
        }

        this.setState({ uploadProgress: percentage });

        this.props.progressCallback(percentage, this.state.uuid);
        
    }

    render() {

        return (
            null
        );

    }
}

export {
    ValidationUploader,
} 