import React, { useEffect, useState } from 'react';
import * as style from '../Constants/styles';
import { Card, Button } from 'react-bootstrap';
import { formStyle, margin } from '../Constants/styles';
import { useNavigate } from 'react-router-dom';
import Auth from '../Auth/Auth';

interface props {
  auth: Auth;
  refreshApp: () => void;
}

function Home (props: props) {
  const [isAuth, setIsAuth] = useState(props.auth.isAuthenticated());
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuth) {
      console.log("Navigating to /contentBrowser/Durable");
      navigate('/contentBrowser/Durable', { replace: true });
    }
  }, [isAuth]);

  const login = async () => {
    if (await props.auth.login()) {
      console.log("Setting isAuth to true.");
      setIsAuth(true);
      props.refreshApp();
    }
  }

  const { isApprovedCreator, isSignedInWithMSA } = props.auth;

  var isSignedMSA = isSignedInWithMSA();
  var isApproved = isApprovedCreator();

  if (isAuth) {
    return (
      <main className="container">
        <div className="row">
          <Card style={formStyle} className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div style={style.center}>
              <h4>You are logged in!</h4>
            </div>
          </Card>
        </div>
      </main>
    );
  }
  if (!isApproved && isSignedMSA) {
    return (
      <main className="container">
        <div className="row">
          <Card style={formStyle} className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div style={style.center}>
              <h4>
                You are not an approved creator! Please{' '}
                <a style={{ cursor: 'pointer' }} href="https://go.microsoft.com/fwlink/?linkid=2133734">
                  Apply Here
      </a>{' '}
                if you wish to become one.
    </h4>
            </div>
          </Card>
        </div>
      </main>
    );
  }

  else {
    return (
      <main className="container">
        <div className="row">
          <Card style={formStyle} className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div style={style.center}>
              <Button variant="success" style={margin} onClick={async () => await login()} size="lg">LOG IN</Button>
              <h4>You are not logged in! If you are an approved creator please log in to continue!</h4>
              <h4>
                If you are not an approved creator feel free to {' '}
                <a style={{ cursor: 'pointer' }} href="https://go.microsoft.com/fwlink/?linkid=2133734" target="_blank" rel="noopener noreferrer">
                  Apply Here</a>.
                </h4>
            </div>
          </Card>
        </div>
      </main>
    );
  }
}

export default Home;
