import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { center, updateText } from '../Constants/styles'
import Auth from '../Auth/Auth'
import { MarketplacePackage } from '../Models/AugerOffers/MarketplacePackage';
import { GetDeveloperIds, GetMarketplacePackageDetails, GetPublisherIds, IngestMarketplaceProduct, UploadMediaAsset } from '../Api/RomaWebApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages } from '@fortawesome/free-regular-svg-icons';
import { MarketplaceProductThumbnails } from './MarketplaceProductThumbnails';
import { MarketplaceSummary, MisMediaAsset, MisStatus, PackageDetails, PlatformDetails } from '../Models/AugerOffers/MarketplaceDetails';

interface Props {
    auth: Auth,
    isNew: boolean,
    validate: boolean,
    onSave: () => Promise<void>,
    onCancel: () => void,
    processFormInfo: (marketplacePackage: MarketplacePackage | undefined, propertyName: keyof MarketplacePackage, propertyValue?: any) => void,
    marketplacePackage: MarketplacePackage,
    marketplacePackagesDetails: PackageDetails<MarketplaceSummary>[],
    contentPackagesDetails: PackageDetails<PlatformDetails>[]
}

export function MarketplaceProductForm(props: Props) {
    const [marketplacePackageFormFields, setMarketplacePackageFormFields] = useState<JSX.Element>();
    const [validOffer, setValidOffer] = useState(false);
    const [developerIds, setDeveloperIds] = useState([]);
    const [isModified, setIsModified] = useState(false);
    const [editMediaAssets, setEditMediaAssets] = useState(false);
    const [waiting, setWaiting] = useState(false);

    useEffect(() => {
        if (props.marketplacePackage.publisherId.value) {
            GetDeveloperIds(props.auth, props.marketplacePackage.publisherId.value).then((result: any) => {
                setDeveloperIds(result);
            });
        }
    }, [props.auth, props.marketplacePackage.publisherId.value]);

    useEffect(() => {
        let enablePC = true; // this.props.params.offerId ? false : true;
        setMarketplacePackageFormFields(props.marketplacePackage.getSubmissionFormFields(processFormInfo, props.isNew, enablePC, enablePC, !enablePC, {
            packageNames: props.contentPackagesDetails?.map(contentPackageDetails => contentPackageDetails.packageName).filter(packageName => !props.marketplacePackagesDetails.map(marketplacePackageDetails => marketplacePackageDetails.packageName).includes(packageName)),
            developerIds: developerIds
        }));
    }, [developerIds, props.contentPackagesDetails]);

    function processFormInfo(marketplacePackage: MarketplacePackage | undefined, propertyName: keyof MarketplacePackage, propertyValue?: any) { 
        props.processFormInfo(marketplacePackage, propertyName, propertyValue);

        if (marketplacePackage && propertyValue !== "Upload new Content Package...") {
            setIsModified(true);
        
            let enablePC = true; // this.props.params.offerId ? false : true;
            setMarketplacePackageFormFields(marketplacePackage.getSubmissionFormFields(processFormInfo, props.isNew, enablePC, enablePC, !enablePC, {
                packageNames: props.contentPackagesDetails?.map(contentPackageDetails => contentPackageDetails.packageName).filter(packageName => !props.marketplacePackagesDetails.map(marketplacePackageDetails => marketplacePackageDetails.packageName).includes(packageName)),
                developerIds: developerIds
            }));

            if (props.validate) {
                setValidOffer(marketplacePackage.validateOffer(enablePC, enablePC, !enablePC).passed);
            }
        }
    }

    function onEditMediaAssets() {
        setEditMediaAssets(true);
    }

    function onSaveMediaAssets(submission: MarketplacePackage) {
        setEditMediaAssets(false);
        setIsModified(true);
    }

    async function onSave() {
        setWaiting(true);

        let marketplaceIngestRequest = props.marketplacePackage.MarketplaceIngestRequest;

        marketplaceIngestRequest.filenameUploadUrls = {};

        if (props.marketplacePackage.mediaAssets.value) {
            await Promise.all(props.marketplacePackage.mediaAssets.value.map(async (mediaAsset: MisMediaAsset) => {
                if (mediaAsset.file) {
                    marketplaceIngestRequest.filenameUploadUrls[mediaAsset.filename as keyof object] = await UploadMediaAsset(props.auth, mediaAsset.file, mediaAsset.filename, marketplaceIngestRequest.submissionId, props.marketplacePackage.packageName.value as string, marketplaceIngestRequest.developerId, marketplaceIngestRequest.publisherId);
                }
            }));
        }

        const result = await IngestMarketplaceProduct(props.auth, marketplaceIngestRequest);

        let status = MisStatus.Commited;

        while (status === MisStatus.Commited) {
            await new Promise(resolve => setTimeout(resolve, 1000));

            const resultDetails = await GetMarketplacePackageDetails(props.auth, result.packageName);

            status = resultDetails.draft.ingestionStatus ?? MisStatus.Commited;
        }
        
        result.draft.ingestionStatus = status;

        const foundIndex = props.marketplacePackagesDetails.findIndex((marketplacePackage) => marketplacePackage.packageName === result.packageName);
        props.marketplacePackagesDetails[foundIndex] = result;

        await props.onSave();

        setWaiting(false);
    }

    function onCancelMediaAssets() {
        setEditMediaAssets(false);
        setIsModified(false);
    }

    return (
        <div className="container create-new">
            <div className="row">
                <main className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        {props.marketplacePackage && <MarketplaceProductThumbnails show={editMediaAssets} auth={props.auth} marketplacePackage={props.marketplacePackage} onSave={onSaveMediaAssets} onCancel={onCancelMediaAssets} />}
                        {marketplacePackageFormFields}
                        <div style={{display: "flex", justifyContent: "flex-end"}}>
                            <FontAwesomeIcon style={{position: "absolute", bottom: "30px", left: "50px"}} size="xl" icon={faImages} className="icon" onClick={() => onEditMediaAssets()} />
                            <Button style={{ margin: 10, width: 100 }} variant="primary"
                                onClick={async event => await onSave()}
                                title={validOffer ? "" : "Please correct any errors indicated in red above before submitting."}
                                disabled={!validOffer || !isModified}>{"SAVE"}</Button>
                            <Button style={{ margin: 10, width: 100 }} variant="default"
                                onClick={event => props.onCancel()}>{"CANCEL"}</Button>
                        </div>
                </main>
            </div>
            <Modal show={waiting} onHide={() => props.onCancel()} backdrop={"static"} keyboard={false}>
                <Modal.Header>
                    <Modal.Title style={center}>Please Wait</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                            <div style={center} >
                                <h3 style={updateText}>Processing...</h3>
                                <Button style={{margin: 10}} onClick={() => setWaiting(false)}>CANCEL</Button>
                            </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}
