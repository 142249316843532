import { CSSProperties } from "react";

const panelStyle = {
    margin: "auto",
    maxWidth: 1250,
    padding: 50,
    backgroundColor: "rgba(255,255,255,0.9)"
};

export const revenatorStyle = {
    backgroundColor: "rgba(0,0,0,0)",
    border: "none",
    display: "block",
    marginTop: 10,
    maxWidth: 300,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 10,
};

export const maxWidth150 = {
    maxWidth: 150,
};

export const maxWidth300 = {
    maxWidth: 300,
};

export const standardText = {
    color: "black",
    margin: "auto",
    textAlign: "center",
    padding: 20
};

export const warningText: CSSProperties = {
    color: "black",
    background: "yellow",
    fontWeight: "bolder",
    margin: "auto",
    textAlign: "center",
    padding: 20
};

export const errorText: CSSProperties = {
    color: "black",
    background: "red",
    fontWeight: "bolder",
    margin: "auto",
    textAlign: "center",
    padding: 20
};

export const successText: CSSProperties = {
    color: "white",
    background: "green",
    margin: "auto",
    textAlign: "center",
    padding: 20
};

export const updateText: CSSProperties = {
    color: "white",
    background: "blue",
    margin: "auto",
    textAlign: "center",
    padding: 20
};

const logoStyle = {
    backgroundColor: "rgba(0,0,0,0)",
    border: "none",
    display: "block",
    marginTop: 60,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: -20,
};
export const creatorLogo = {
    backgroundColor: "rgba(0,0,0,0)",
    border: "none",
    display: "block",
    marginTop: 0,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 20,
    maxWidth: 300
};

const attributionsFooter = {
    backgroundColor: "rgba(0,0,0,0)",
    border: "none",
    display: "inline",
    boxShadow: "unset",
    color: "rgba(0,0,0)"
};

const mpLogoStyle = {
    backgroundColor: "rgba(0,0,0,0)",
    border: "none",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: -50,
};

const navBottom = {
    backgroundColor: "rgb(31,31,31)",
    border: "none",
    marginLeft: "auto",
    marginRight: "auto",
    minHeight: 50,
    boxShadow: "0 0 15px -3px rgba(0,0,0,1.57)"
};

const msStudiosLogo = {
    backgroundColor: "rgba(0,0,0,0)",
    border: "none",
    display: "block",
    marginTop: 50
};

const msStudiosLogoFooter = {
    backgroundColor: "rgba(0,0,0,0)",
    border: "none",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto"
};

const footerStyle = {
    backgroundColor: "rgba(0,0,0,0)",
    border: "none",
    display: "block",
    position: "absolute",
    bottom: "0px",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center"
};

const loadingStyle = {

    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 100,

}

const signUpDiv = {
    maxWidth: 800,
    margin: "auto"
};

const center: CSSProperties = {
    margin: "auto",
    textAlign: "center",
    paddingTop: 5,
    paddingBottom: 5
};

const reportDetails = {
    maxWidth: 300,
};

const margin = {
    marginTop: 20,
    marginBottom: 20
};

const formStyle = {
    boxShadow: "0 0 15px -3px rgba(0,0,0,0.57)"
};

const signInStyle = {
    maxWidth: 400,
    padding: 40,
    marginTop: 75,
    marginLeft: "auto",
    marginRight: "auto",
    boxShadow: "0 0 15px -3px rgba(0,0,0,0.57)"
};

export {
    margin,
    center,
    panelStyle,
    logoStyle,
    signUpDiv,
    formStyle,
    signInStyle,
    msStudiosLogo,
    mpLogoStyle,
    navBottom,
    msStudiosLogoFooter,
    attributionsFooter,
    footerStyle,
    loadingStyle,
    reportDetails,
};