import React, { useState, useEffect } from 'react';
import withRouter from '../withRouter';
import ReactMarkdown, { uriTransformer } from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { Modal, Card } from 'react-bootstrap';

interface Props {
    params: any;
    prefix: string;
    path: string;
}

function Documentation(props: Props) {
    const [markdown, setMarkdown] = useState('');
    const [image, setImage] = useState();
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const page = props.params.page;
            const file = await import(`./${props.prefix}-${page}.md`);
            const response = await fetch(file.default);
            const text = await response.text();

            setMarkdown(text);
            setImage(undefined);
            setShowModal(false);

            document.title = "Documentation | Marketplace Content Portal";
        };

        fetchData();
    }, [props.params.page, props.prefix]);

    const handleOpen = (event: any) => {
        if (event.target.src) {
            setImage(event.target.src);
            setShowModal(true);
        }
    };

    const handleClose = () => {
        setShowModal(false);
    };

    const transformLinkUri = (input: string) => {
        const url = (input || '').trim();
        const first = url.charAt(0);

        if (first === '/') {
            const result = props.path.replace('/' + props.params.page, url);

            return result;
        } else {
            const result = uriTransformer(input);

            return result;
        }
    };

    const transformImageUrl = (input: string) => {
        if (input.startsWith("/Documentation/") || input.startsWith("https//")) {
            return uriTransformer(input);
        } else {
            return "/Documentation/" + input;
        }
    };

    return (
        <div>
            <Card onClick={handleOpen}>
                <ReactMarkdown rehypePlugins={[rehypeRaw]} children={markdown} transformImageUri={transformImageUrl} transformLinkUri={transformLinkUri} />
            </Card>
            <Modal size="sm" show={showModal} onHide={handleClose} >
                <img alt="" src={image} style={{ transform: "translate(-50%)", left: "50%", position: "absolute"}} width="250%" />
            </Modal>
        </div>
    );
}

export default withRouter(Documentation);