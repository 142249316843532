import React from 'react';
import { MarketplaceItem, OfferField, visibleToOfferAndMetaWorkFlow } from './MarketplaceItem';
import { AUGER_OFFER_TYPES, MAX_UPLOAD_SIZE } from '../../Constants/enums';
import { DropDownFormField, TagFormField, TextAreaFormField, TextFormField } from '../../Components/FormFields/FormField';
import { Col, Container, Row } from 'react-bootstrap';
import { MarketplaceIngestRequest, MisPackageContentType, MisTag, MisMediaAsset, MarketplaceSummary, getLatestMarketplacePackageDetailsResponse, PackageDetails, MisMediaAssetTag } from './MarketplaceDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { faGift } from '@fortawesome/free-solid-svg-icons';
import placeholder from "../../Images/Placeholder.jpg";

interface CategorySections {
    [key: string]: string[];
}

interface Props {
    marketplacePackagesDetails: PackageDetails<MarketplaceSummary>[];
}

export class MarketplacePackage extends MarketplaceItem {
    marketplacePackagesDetails: PackageDetails<MarketplaceSummary>[];

    submissionId = new OfferField<string | null>(null, "Submission Id", false);
    offerTitle = new OfferField<string | null>(null, "Offer Title", true);
    packageContentType = new OfferField<string | null>(null, "Pack Type", true, visibleToOfferAndMetaWorkFlow);
    packageVersion = new OfferField<string | null>(null, "Package Version", false);
    publisherId = new OfferField<string | null>(null, "Publisher ID", true);
    developerId = new OfferField<string | null>(null, "Developer Id", true);
    priceInUSD = new OfferField<string | null>(null, "Price In USD", true);
    offerType = new OfferField<string | null>(AUGER_OFFER_TYPES.MIS_OFFER, "Offer Type", true);
    packageName = new OfferField<string | null>(null, "Package Name", true);
    submissionType = new OfferField<string | null>(null, "Submission Type", false);
    title = new OfferField<string | null>(null, "Title", true);
    category = new OfferField<string | null>(null, "Category", true);
    section = new OfferField<string | null>(null, "Section", true);
    ingestionStatus = new OfferField<string | null>(null, "Ingestion Status", false);
    ingestionVersion = new OfferField<string | null>(null, "Ingestion Version", false);
    mediaAssets = new OfferField<MisMediaAsset[]>([], "Media Assets", false);
    deletedFiles = new OfferField<string[]>([], "Deleted Files", false);
    description = new OfferField<string | null>(null, "Product Description", true);
    keywords = new OfferField<string[]>([], "Keywords", false);

    constructor(data: any) {
        super(data);
        this.marketplacePackagesDetails = data.marketplacePackagesDetails;
        
        for (var prop in this) {
            if (data.hasOwnProperty(prop)) {
                if (this.hasOwnProperty(prop)) {
                    const offerField = this[prop] as OfferField<any>;

                    offerField.value = data[prop];

                    if (offerField.convertJson === true) {
                        try {
                            offerField.value = JSON.parse(offerField.value as string)
                        } catch(exception) { }
                    }

                } else {
                    console.log(`Can't find prop "${prop}" in ${this.offerType.value} model!`, this);
                    throw new Error(`Can't find prop "${prop}" in ${this.offerType.value} model!`);
                }
            }
        }

        if (this.priceInUSD.value) {
            let price = parseFloat(this.priceInUSD.value as string);

            this.priceInUSD.value = price.toFixed(2);
        }

        if (this.mediaAssets.value.length === 0) {
            fetch(placeholder).then(response => response.blob()).then(blob => {
                this.mediaAssets.value.push({ filename: "Placeholder.jpg", url: URL.createObjectURL(blob), tags: [MisMediaAssetTag.Thumbnail] });
            });
        }

        this.packageName.validate = function (offer?, value?: string | null, originalValue?) {
            const marketplacePackage = (offer as any as MarketplacePackage);
            const valueAsString = value as string;

            if (!value || value === "Upload new Content Package...") {
                return {
                    result: null
                }
            }

            let packageNameStartsWithShortName = valueAsString.startsWith(marketplacePackage.publisherId.value as string);
            let matchingSubmission = marketplacePackage.marketplacePackagesDetails?.find(marketplacePackageDetails => marketplacePackageDetails.packageName === value) !== undefined;
            let packageNameNotFoundError = 'Package name not found. Please verify you are using the latest SDK, do a "Clean All", then "Build & Export" and choose "Flight Simulator Marketplace."'

            return {
                result: (!originalValue || originalValue === '' || value === originalValue) && valueAsString.length <= 100 && valueAsString.length > 2 && packageNameStartsWithShortName && (matchingSubmission === false) ? 'success' : 'error',
                message : valueAsString.length > 100 ? "Package name too long." : valueAsString.length === 0 ? packageNameNotFoundError : valueAsString.length <=2 ? "Package name too short." : valueAsString.trim().length !== valueAsString.length ? "Enter valid package name." : (packageNameStartsWithShortName === false) ? "Package name must start with short name" :  matchingSubmission ? "Marketplace product already exists with the same package name." : "Package name for a resubmission or update must be the same as the previous submission. Please correct the package name and resubmit."
            };
        } 
    }

    get MarketplaceIngestRequest(): MarketplaceIngestRequest {
        return {
            submissionId: this.submissionId.value as string,
            businessInfo: {
                priceInUSD: this.priceInUSD.value as string,
                thirdPartyShortName: this.publisherId.value as string
            },
            packageName: this.packageName.value as string,
            developerId: this.developerId.value as string,
            publisherId: this.publisherId.value as string,
            marketplaceInfo: {
                title: this.title.value as string,
                keywords: this.keywords.value,
                layout: { mediaAssets: this.mediaAssets.value },
                packageContentType: this.packageContentType.value as MisPackageContentType,
                sections: [{
                    category: (this.category.value as string).toUpperCase(),
                    section: (this.section.value as string).toUpperCase()
                }]
            },
            localizedTexts: {
                "en-US": {
                    "texts": {
                        "Description": this.description.value as string
                    }
                }
            },
            deleteExistingFiles: false,
            deletedFilenames: this.deletedFiles.value,
            waitForContentIngestion: true,
            // rentalInfo: {
            //     freeTrial: {
            //         durationInDays: 1,
            //         version: "3"
            //     },
            //     prices: [
            //         {
            //             priceInUSD: "1.99",
            //             durationInDays: 3
            //         }
            //     ]
            // }
        }
    }

    getSubmissionFormFields(formInfoCallback: <V>(offer: MarketplacePackage | undefined, propertyName: keyof MarketplacePackage, value?: V) => void, isNew: boolean, enablePCAndXbox: boolean, enablePCOnly: boolean, enableXboxOnly: boolean, context?: any): JSX.Element {
        const offerTypeOptions = Object.entries(MisPackageContentType).map(([key, value]) => {
            return {
                option: value,
                disabled: false
            };
        });

        const categorySections: CategorySections = {
            "UNKNOWN": [
                "UNKNOWN"
            ],
            "AIRCRAFT": [
                "AIRPLANE",
                "ROTORCRAFT",
                "ULM",
                "GLIDER",
                "BALLOON",
                "AVIONIC",
                "OTHER"
            ],
            "AIRPORTS": [
                "AIRPORT",
                "HELIPORT",
                "GLIDEPORT",
                "BALLOONPORT",
                "SEAPLANE BASE",
                "ULTRALIGHT"
            ],
            "WORLD": [
                "LANDMARK",
                "SCENERY",
                "ENVIRONMENT",
                "WORLD UPDATE",
                "TRAFFIC",
                "WILDLIFE",
                "GROUND SERVICE",
                "LIGHTING"
            ],
            "CUSTOMIZATION": [
                "LIVERY"
            ]
        }

        const categoryOptions = Object.keys(categorySections).map(value => { return { option: value, disabled: false } });
        const sectionOptions = categorySections[(this.category.value as string)?.toUpperCase() ?? categoryOptions[0].option].map(value => { return { option: value, disabled: false } });
        const developerIdOptions = (context?.developerIds as string[])?.map((developerId: string) => { return { option: developerId, disabled: false }});
        const packageNameOptions = (context?.packageNames as string[])?.map((packageName: string) => { return { option: packageName, disabled: false }});

        if (this.packageName.value && !packageNameOptions.find(o => o.option === this.packageName.value)) {
            packageNameOptions?.push({ option: this.packageName.value as string, disabled: false });
        }

        packageNameOptions?.push({ option: "Upload new Content Package...", disabled: false });

        const tagOptions = Object.keys(MisTag).map(value => { return { key: value, value: value } });
        const sizeInMb = MAX_UPLOAD_SIZE / 1024 / 1024;

        return (
            <Container key={"details"}>
                <Row className="show-grid">
                    <Col md={6}>
                        <TextFormField<MarketplacePackage>
                            key={"title"}
                            context={this}
                            value={this.title.value ?? ""}
                            title={"Title"}
                            placeholder={"Title"}
                            helpContent={"Extracted from package."}
                            isNew={isNew}
                            propertyName={"title"}
                            returnCallback={formInfoCallback}
                            offerUndo={false}
                            validateCallback={this.offerTitle.validate} />
                    </Col>
                    <Col md={2}>
                        <TextFormField<MarketplacePackage>
                            key={"priceInUSD"}
                            context={this}
                            value={this.priceInUSD.value ?? ""}
                            title={"Price"}
                            placeholder={"9.99"}
                            helpContent={"Extracted from package."}
                            isNew={isNew}
                            propertyName={"priceInUSD"}
                            returnCallback={formInfoCallback}
                            offerUndo={false}
                            validateCallback={this.priceInUSD.validate} />
                    </Col>
                    <Col md={4}>
                        <DropDownFormField<MarketplacePackage>
                            key={"packageName"}
                            context={this}
                            value={this.packageName.value ?? ""}
                            title={"Package Name"}
                            placeholder={"Select Package Name"}
                            helpContent={"Extracted from package."}
                            isNew={isNew}
                            disabled={!isNew}
                            propertyName={"packageName"}
                            returnCallback={formInfoCallback}
                            offerUndo={false}
                            validateCallback={this.packageName.validate} 
                            optionsList={packageNameOptions} />
                    </Col>
                </Row>
                <Row className="show-grid">
                    <Col md={4}>
                        <DropDownFormField<MarketplacePackage>
                            key={"packageContentType"}
                            context={this}
                            value={(this.packageContentType.value === null) ? MisPackageContentType.Unknown : this.packageContentType.value}
                            title={"Content Type"}
                            helpContent={"Extracted from package."}
                            isNew={isNew}
                            disabled={true}
                            propertyName={"packageContentType"}
                            returnCallback={formInfoCallback}
                            offerUndo={false}
                            validateCallback={this.packageContentType.validate}
                            optionsList={offerTypeOptions} />
                    </Col>
                    <Col md={4}>
                        <DropDownFormField<MarketplacePackage>
                            key={"category"}
                            context={this}
                            value={(this.category.value as string)?.toUpperCase()}
                            title={"Category"}
                            placeholder={"Select Category"}
                            helpContent={"Extracted from package."}
                            isNew={isNew}
                            propertyName={"category"}
                            returnCallback={formInfoCallback}
                            offerUndo={false}
                            validateCallback={this.category.validate}
                            optionsList={categoryOptions} />
                    </Col>
                    <Col md={4}>
                        <DropDownFormField<MarketplacePackage>
                            key={"section"}
                            context={this}
                            value={(this.section.value as string)?.toUpperCase()}
                            title={"Section"}
                            placeholder={"Select Section"}
                            helpContent={"Extracted from package."}
                            isNew={isNew}
                            propertyName={"section"}
                            returnCallback={formInfoCallback}
                            offerUndo={false}
                            validateCallback={this.section.validate}
                            optionsList={sectionOptions} />
                    </Col>
                </Row>
                <Row className="show-grid">
                    <Col md={6}>
                        <TextFormField<MarketplacePackage>
                            key={"publisherId"}
                            context={this}
                            value={this.publisherId.value ?? ""}
                            title={"Publisher Id"}
                            placeholder={"Publisher Id"}
                            helpContent={"Extracted from package."}
                            isNew={isNew}
                            disabled={true}
                            propertyName={"publisherId"}
                            returnCallback={formInfoCallback}
                            offerUndo={false}
                            validateCallback={this.publisherId.validate} />
                    </Col>
                    <Col md={6}>
                        <DropDownFormField<MarketplacePackage>
                            key={"developerId"}
                            context={this}
                            value={this.developerId.value ?? ""}
                            title={"Developer Id"}
                            placeholder={"Select Developer Id"}
                            helpContent={"Extracted from package."}
                            isNew={isNew}
                            disabled={!isNew}
                            propertyName={"developerId"}
                            returnCallback={formInfoCallback}
                            offerUndo={false}
                            validateCallback={this.developerId.validate}
                            optionsList={developerIdOptions ?? []} />
                    </Col>
                </Row>
                <Row className="show-grid">
                    <Col>
                        <TextAreaFormField<string, MarketplacePackage>
                            key={"productDescription"}
                            context={this}
                            value={this.description.value ?? ''}
                            title={"Description"}
                            placeholder={"Enter Product Description"}
                            helpContent={"10000 character limit"}
                            propertyName={"description"}
                            isNew={isNew}
                            returnCallback={formInfoCallback}
                            validateCallback={this.description.validate} />
                    </Col>
                </Row>
                <Row className="show-grid">
                    <Col>
                        <TagFormField<MarketplacePackage>
                            key={"keywords"}
                            context={this}
                            value={this.keywords.value}
                            title={"Keywords"}
                            placeholder={"Enter keywords"}
                            helpContent={"Extracted from package."}
                            isNew={isNew}
                            propertyName={"keywords"}
                            returnCallback={formInfoCallback}
                            offerUndo={false} />
                    </Col>
                </Row>
            </Container>);
    }

    setFieldFromName(propertyName: keyof MarketplaceItem, propertyValue?: any) {
        super.setFieldFromName(propertyName, propertyValue);

        // if (propertyName === "packType") {
        //     //console.log("removing binary due to packType change");
        //     this.binaryFile.value = null;
        //     durableOffer.validationResult.value = null;
        // }

        return this;
    }

    static marketplaceStringSortBy(rowA: any, rowB: any, id: string, desc: boolean): number {
        return rowA.original[id].localeCompare(rowB.original[id]);
    }

    static marketplaceDetailsStringSortBy(rowA: any, rowB: any, id: string, desc: boolean): number {
        const rowADetails: any = getLatestMarketplacePackageDetailsResponse<MarketplaceSummary>(rowA.original);
        const rowBDetails: any = getLatestMarketplacePackageDetailsResponse<MarketplaceSummary>(rowB.original);

        return rowADetails[id].localeCompare(rowBDetails[id]);
    }

    static getColumnHeaders(onEdit: (submission: PackageDetails<MarketplaceSummary>) => void, onRelease: (index: number, submission: MarketplacePackage) => void, onEditContentPackage: (index: number, data: PackageDetails<MarketplaceSummary>) => void, isFirstParty = false) {
        var columnStyle = {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        }
        const columns = [
            {
                Header: 'Publisher Id',
                accessor: 'publisherId', // String-based value accessors!
                sortType: this.marketplaceStringSortBy,
                maxWidth: 80,
                style: columnStyle,
                show: isFirstParty
            },
            {
                Header: 'Developer Id',
                accessor: 'developerId', // String-based value accessors!
                sortType: this.marketplaceStringSortBy,
                maxWidth: 200,
                style: columnStyle
            },
            {
                Header: 'Title',
                accessor: 'title', // String-based value accessors!
                sortType: this.marketplaceDetailsStringSortBy,
                style: columnStyle,
                Cell: ({row}: any) => (
                    getLatestMarketplacePackageDetailsResponse<MarketplaceSummary>(row.original).title
                )
            },
            {
                Header: 'Ingestion Status',
                accessor: 'ingestionStatus', // String-based value accessors!
                sortType: this.marketplaceDetailsStringSortBy,
                maxWidth: 65,
                style: columnStyle,
                Cell: ({row}: any) => (
                    getLatestMarketplacePackageDetailsResponse<MarketplaceSummary>(row.original).ingestionStatus
                )
            },
            {
                Header: 'Release Status',
                accessor: 'releaseStatus', // String-based value accessors!
                sortType: this.marketplaceDetailsStringSortBy,
                maxWidth: 65,
                style: columnStyle,
                Cell: ({row}: any) => (
                    getLatestMarketplacePackageDetailsResponse<MarketplaceSummary>(row.original).releaseStatus
                )
            },
            {
                Header: 'Package',
                columns: [
                    {
                        Header: 'Name',
                        accessor: 'packageName', // String-based value accessors!
                        sortType: this.marketplaceStringSortBy,
                        style: columnStyle
                    },
                    {
                        Header: 'Content Type',
                        accessor: 'packageContentType', // String-based value accessors!
                        sortType: this.marketplaceDetailsStringSortBy,
                        maxWidth: 65,
                        style: columnStyle,
                        Cell: ({row}: any) => (
                            getLatestMarketplacePackageDetailsResponse<MarketplaceSummary>(row.original).packageContentType
                        )
                    }
                ]
            },
            {
                Header: "Action",
                accessor: 'action',
                disableSortBy: true,
                disableColumnFilter: true,
                maxWidth: 30,
                style: columnStyle,
                Cell: ({row}: any) => (
                    <div>
                        <FontAwesomeIcon icon={faGift} style={{cursor: "pointer", marginRight: "20px"}} className="icon" onClick={() => onEditContentPackage(row.index, row.original)} />
                        <FontAwesomeIcon icon={faEdit} style={{cursor: "pointer"}} className="icon" onClick={() => onEdit(row.original)} />
                        <FontAwesomeIcon icon={faPaperPlane} style={{cursor: "pointer", marginLeft: "20px"}} className="icon" onClick={() => onRelease(row.index, row.original)} />
                    </div>
                )
            }
        ]

        return columns;
    }

    static getColumnDetailsHeaders() {
        var columnStyle = {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        }

        const columns = [
            {
                Header: 'Publisher Id',
                accessor: 'publisherId', // String-based value accessors!
                maxWidth: 80,
                style: columnStyle
            },
            {
                Header: 'Developer Id',
                accessor: 'developerId', // String-based value accessors!
                maxWidth: 200,
                style: columnStyle
            },
            {
                Header: 'Package Version',
                accessor: 'packageVersion', // String-based value accessors!
                maxWidth: 65,
                style: columnStyle
            }
        ]

        return columns;
    }

    convertToColumnData() {
        var reportEntry = this.export();

        return (
            {
                submission: this,
                packageVersion: reportEntry.packageVersion,
                publisherId: reportEntry.publisherId,
                releaseStatus: reportEntry.releaseStatus,
                developerId: reportEntry.developerId,
                packageName: reportEntry.packageName,
                packageContentType: reportEntry.packageContentType,
                priceInUSD: reportEntry.priceInUSD,
                title: reportEntry.title,
                ingestionStatus: reportEntry.ingestionStatus
            }
        )
    }

    static get defaultListSorting() {
        return [
            {
                id: "developerId",
                desc: false
            },
            {
                id: "title",
                desc: false
            }
        ]
    }

    static get defaultFirstPartyListSorting() {
        return [
            {
                id: "creatorName",
                desc: false
            },
            {
                id: "title",
                desc: false
            }
        ]
    }

    static get MAX_FILE_SIZE() {
        return MAX_UPLOAD_SIZE;
    }
}