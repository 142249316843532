import React, { useEffect, useState } from 'react';
import { Button, Card, FormCheck, Modal } from 'react-bootstrap';
import { AUGER_OFFER_TYPES } from '../Constants/enums'
import Auth from '../Auth/Auth';
import * as utils from '../Constants/utils';
import { GetContentPackages, GetMarketplacePackages, IngestContentPackage, TransferToPrerelease } from '../Api/RomaWebApi';
import { MarketplaceProductForm } from './MarketplaceProductForm';
import { MarketplacePackage } from '../Models/AugerOffers/MarketplacePackage';
import AugerOfferFactory from '../Factories/AugerOfferFactory';
import { MarketplaceSummary, PackageDetails, PlatformDetails, PublishingTarget, SubmissionType, getLatestContentPackageDetailsResponse, getLatestMarketplacePackageDetailsResponse } from '../Models/AugerOffers/MarketplaceDetails';
import { MarketplaceItem } from '../Models/AugerOffers/MarketplaceItem';
import ReactTable from '../ReactTable';
import { MarketplaceProductDetails } from './MarketplaceProductDetails';
import { center, warningText } from '../Constants/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faForward } from '@fortawesome/free-solid-svg-icons';
import { ContentPackageForm } from './ContentPackageForm';
import { ContentPackage } from '../Models/AugerOffers/ContentPackage';

const { v4: uuidv4 } = require('uuid');

interface Props {
    auth: Auth;
}

export default function MarketplaceProductBrowser(props: Props) {
    const [marketplacePackage, setMarketplacePackage] = useState<MarketplacePackage>();
    const [contentPackage, setContentPackage] = useState<ContentPackage>();
    const [contentPackagesDetails, setContentPackagesDetails] = useState<PackageDetails<PlatformDetails>[]>([]);
    const [marketplacePackagesDetails, setMarketplacePackagesDetails] = useState<PackageDetails<MarketplaceSummary>[]>([]);
    const [editMarketplacePackage, setEditMarketplacePackage] = useState(false);
    const [uploadContentPackage, setUploadContentPackage] = useState(false);
    const [releasePC, setReleasePC] = useState(false);
    const [releaseXbox, setReleaseXbox] = useState(false);
    const [releaseMarketplace, setReleaseMarketplace] = useState(false);
    const [releasePackages, setReleasePackages] = useState(false);
    const [isNew, setIsNew] = useState(false);
    const [error, setError] = useState<any>();

    useEffect(() => {
        GetContentPackages(props.auth).then((result) => {
            if (result.length === 0) {
                console.log("No Offers Found");
                setContentPackagesDetails([]);
                setError("No offers found");
            } else {
                setContentPackagesDetails(result);
            }
        }).catch((error) => {
            console.log("error", error);
            setContentPackagesDetails([]);
            setError(error);
        });

        GetMarketplacePackages(props.auth).then((result: PackageDetails<MarketplaceSummary>[]) => {
            if (result.length === 0) {
                console.log("No Offers Found");
                setMarketplacePackagesDetails([]);
                setError("No offers found");
            } else {
                setMarketplacePackagesDetails(result);
            }
        }).catch((error) => {
            console.log("error", error);
            setMarketplacePackagesDetails([]);
            setError(error);
        });
    }, [props.auth]);

    function processMarketplaceFormInfo(submission: MarketplacePackage | undefined, propertyName: keyof MarketplacePackage , propertyValue?: any) { 
        if (propertyValue == null) {
            return;
        } else if (propertyName === "packageName" && propertyValue === "Upload new Content Package...") {
            setContentPackage(AugerOfferFactory.build(AUGER_OFFER_TYPES.CIDS_OFFER, { auth: props.auth }) as ContentPackage);
            setUploadContentPackage(true);            
            return;
        }
    
        submission?.setFieldFromName(propertyName as keyof MarketplaceItem, propertyValue);
    }

    function processContentFormInfo(submission: ContentPackage | undefined, propertyName: keyof ContentPackage, propertyValue?: any) { 
        if (propertyValue == null || propertyName === "submissionId" || propertyName === "publishingTarget"){
            return;
        }
    
        if (propertyName === "packageName") {
            const publisherId = propertyValue.split('-')[0];
            marketplacePackage?.setFieldFromName("publisherId" as keyof MarketplaceItem, publisherId);
        }

        marketplacePackage?.setFieldFromName(propertyName as keyof MarketplaceItem, propertyValue);
    }

    function onNew() {
        const marketplacePackage = AugerOfferFactory.build(AUGER_OFFER_TYPES.MIS_OFFER, { auth: props.auth, marketplacePackagesDetails: marketplacePackagesDetails }) as MarketplacePackage;

        marketplacePackage.submissionId.value = uuidv4();

        setMarketplacePackage(marketplacePackage);
        setIsNew(true);
        setEditMarketplacePackage(true);
    }

    function onEdit(data: PackageDetails<MarketplaceSummary>) {
        const marketplacePackage = AugerOfferFactory.build(AUGER_OFFER_TYPES.MIS_OFFER, getLatestMarketplacePackageDetailsResponse<MarketplaceSummary>(data)) as MarketplacePackage;

        marketplacePackage.submissionId.value = uuidv4();

        setEditMarketplacePackage(true);
        setIsNew(false);
        setMarketplacePackage(marketplacePackage);
    }

    function onEditContentPackage(index: number, data: PackageDetails<MarketplaceSummary>) {
        const marketplacePackage = AugerOfferFactory.build(AUGER_OFFER_TYPES.MIS_OFFER, getLatestMarketplacePackageDetailsResponse<MarketplaceSummary>(data)) as MarketplacePackage;
        const contentPackageDetails = contentPackagesDetails?.find((contentPackage) => contentPackage.packageName === marketplacePackage.packageName.value);
        const contentPackage = AugerOfferFactory.build(AUGER_OFFER_TYPES.CIDS_OFFER, { auth: props.auth, ...contentPackageDetails }) as ContentPackage;

        setMarketplacePackage(marketplacePackage);
        setContentPackage(contentPackage)
        setIsNew(false);
        setUploadContentPackage(true);
    }

    async function onSaveContentPackage() {
        setUploadContentPackage(false);

        if (!isNew && marketplacePackage && contentPackage) {
            await IngestContentPackage(props.auth, {
                submissionId: contentPackage.submissionId.value as string,
                packageName: contentPackage.packageName.value as string,
                publishingTarget: contentPackage.publishingTarget.value as PublishingTarget,
                publisherId: marketplacePackage.publisherId.value as string,
                developerId: marketplacePackage.developerId.value as string
            }).catch((error) => {
                console.log("error", error);
                setError(error);
            });
        }
    }

    function onReleaseRequest(index: number, data: any) {
        const marketplacePackage = AugerOfferFactory.build(AUGER_OFFER_TYPES.MIS_OFFER, data) as MarketplacePackage;
        const marketplacePackageDetails = marketplacePackagesDetails.find((mp) => mp.packageName === marketplacePackage.packageName.value);
        const contentPackageDetails = contentPackagesDetails?.find((contentPackage) => contentPackage.packageName === marketplacePackage.packageName.value);
        const contentPackage = AugerOfferFactory.build(AUGER_OFFER_TYPES.CIDS_OFFER, contentPackageDetails) as ContentPackage;

        if (marketplacePackageDetails && contentPackageDetails) {
            const latestMarketplacePackageDetails = getLatestMarketplacePackageDetailsResponse(marketplacePackageDetails);
            const latestContentPackageDetails = getLatestContentPackageDetailsResponse(contentPackageDetails);
            
            if (latestMarketplacePackageDetails.releaseStatus === SubmissionType.Draft) {
                setReleaseMarketplace(true);
            }

            if (latestContentPackageDetails.PC.releaseStatus === SubmissionType.Draft) {
                setReleasePC(true);
            }

            if (latestContentPackageDetails.Xbox.releaseStatus === SubmissionType.Draft) {
                setReleaseXbox(true);
            }
        }

        setMarketplacePackage(marketplacePackage);
        setContentPackage(contentPackage);
        setReleasePackages(true);
    }

    function onConfirmReleaseRequest(confirm: boolean) {
        if (confirm && marketplacePackage) {
            const publishingTargets:PublishingTarget[] = [];
            
            if (releasePC) {
                publishingTargets.push(PublishingTarget.PC);
            }

            if (releaseXbox) {
                publishingTargets.push(PublishingTarget.Xbox);
            }

            TransferToPrerelease(props.auth, {
                packageName: marketplacePackage.packageName.value as string,
                developerId: marketplacePackage.developerId.value as string,
                publisherId: marketplacePackage.publisherId.value as string,
                marketplaceRelease: releaseMarketplace,
                contentRelease: releasePC || releaseXbox,
                publishingTargets: publishingTargets
            });

            setMarketplacePackage(undefined);
        }

        setReleasePackages(false);
    }

    async function onSave() {
        if (isNew && marketplacePackage && contentPackage) {
            await IngestContentPackage(props.auth, {
                submissionId: contentPackage.submissionId.value as string,
                packageName: contentPackage.packageName.value as string,
                publishingTarget: contentPackage.publishingTarget.value as PublishingTarget,
                publisherId: marketplacePackage.publisherId.value as string,
                developerId: marketplacePackage.developerId.value as string
            }).catch((error) => {
                console.log("error", error);
                setError(error);
            });
        }

        setEditMarketplacePackage(false);
    }

    function onCancel() {
        setEditMarketplacePackage(false);
        setMarketplacePackage(undefined);
        setContentPackage(undefined);
    }

    function subComponent (row: any) {
        const submission = AugerOfferFactory.build(AUGER_OFFER_TYPES.MIS_OFFER, getLatestMarketplacePackageDetailsResponse<MarketplaceSummary>(row.original)) as MarketplacePackage;
        const contentPackageDetails = contentPackagesDetails?.find((contentPackage) => contentPackage.packageName === submission.packageName.value);
        const contentPackage = AugerOfferFactory.build(AUGER_OFFER_TYPES.CIDS_OFFER, { auth: props.auth, ...contentPackageDetails }) as ContentPackage;

        return <MarketplaceProductDetails auth={props.auth} isNew={false} onSave={onSave} onCancel={onCancel} marketplacePackage={submission} marketplacePackagesDetails={marketplacePackagesDetails} processFormInfo={processMarketplaceFormInfo} contentPackage={contentPackage} contentPackagesDetails={contentPackagesDetails?.map((submission:any) => submission.packageName)} />
    }

    const isFirstParty = utils.isFirstParty(props.auth.getCreatorId());
    const columns = MarketplacePackage.getColumnHeaders(onEdit, onReleaseRequest, onEditContentPackage, isFirstParty);
    const noDataText = "No offers found!";

        //loading
    const lastPageSize = JSON.parse(localStorage.getItem('lastBrowserPageSize') ?? "10");

    let lastFilter:string | null | undefined = localStorage.getItem('lastBrowserFilter');

    if (lastFilter && lastFilter !== 'undefined') {
        lastFilter = JSON.parse(lastFilter);
    } else {
        lastFilter = undefined;
    }

    let lastSorted:string | null | undefined = localStorage.getItem('lastBrowserSort');

    if (lastSorted && lastSorted !== 'undefined') {
        lastSorted = JSON.parse(lastSorted);

        if (lastSorted?.length === 0) {
            lastSorted = undefined;
        }
    } else {
        lastSorted = undefined;
    }

    const customFilter = (rows: any[], globalFilterValue: string) => {
        const filterValues = globalFilterValue.split(',');
        const result = rows.filter(row => {
            const details = getLatestMarketplacePackageDetailsResponse<MarketplaceSummary>(row.original)
            const result = filterValues.find(fv => Object.values(details).find(value => typeof value === 'string' && value?.toLowerCase().includes(fv.trim().toLowerCase())));
            return result?.trim();
        });
        
        return result;
    }

    return (
        <main className="container-fluid">
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    {marketplacePackage && <Modal centered show={editMarketplacePackage} dialogClassName="modal-70w" onHide={() => setEditMarketplacePackage(false)} backdrop={"static"} keyboard={false}>
                        <Modal.Body>
                            <h1 style={center}>{isNew ? "New Marketplace Product" : "Edit Marketplace Product"}</h1>
                            <div style={center}><em>Note: This is a helpful note.</em></div>
                            <MarketplaceProductForm key={marketplacePackage.publisherId.value} auth={props.auth} isNew={isNew} validate={!uploadContentPackage} onSave={onSave} onCancel={onCancel} processFormInfo={processMarketplaceFormInfo} marketplacePackage={marketplacePackage} marketplacePackagesDetails={marketplacePackagesDetails} contentPackagesDetails={contentPackagesDetails} />
                        </Modal.Body>
                    </Modal>}
                    {contentPackage && <ContentPackageForm show={uploadContentPackage} onSave={async () => await onSaveContentPackage()} onHide={() => setUploadContentPackage(false)} auth={props.auth} processFormInfo={processContentFormInfo} isNew={isNew} contentPackage={contentPackage}/>}
                    <Modal show={releasePackages} onHide={() => setReleasePackages(false)} backdrop={"static"} keyboard={false}>
                        <Modal.Header>
                            <Modal.Title style={center}>Request Release</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                                    <div style={center} >
                                        <h3 style={warningText}>This operation cannot be undone.</h3>
                                            <div>
                                                <FormCheck
                                                    key={"PC"}
                                                    label={"PC"}
                                                    checked={releasePC}
                                                    onChange={() => { setReleasePC(!releasePC) }}
                                                    inline/>
                                                <FormCheck
                                                    key={"Xbox"}
                                                    label={"Xbox"}
                                                    checked={releaseXbox}
                                                    onChange={() => { setReleaseXbox(!releaseXbox) }}
                                                    inline/>
                                                <FormCheck
                                                    key={"Marketplace"}
                                                    label={"Marketplace"}
                                                    checked={releaseMarketplace}
                                                    onChange={() => { setReleaseMarketplace(!releaseMarketplace) }}
                                                    inline/>
                                            </div>
                                        <span>
                                        <Button style={{margin: 10}} onClick={() => onConfirmReleaseRequest(false)}><FontAwesomeIcon icon={faBackward} style={{paddingRight: 10}} />CANCEL</Button>
                                        <Button style={{margin: 10}} onClick={() => onConfirmReleaseRequest(true)}>OK<FontAwesomeIcon icon={faForward} style={{paddingLeft: 10}} /></Button>
                                        </span>
                                    </div>
                        </Modal.Body>
                    </Modal>
                    <Card className="text-center">
                        <div style={center} >
                            <Button variant="success" style={{display: "display-inline", margin: "10px"}} size="sm" onClick={() => { onNew() }}>CREATE NEW MARKETPLACE PRODUCT</Button>
                            <div>
                                <em>Tip: Hold shift when sorting to multi-sort!</em>
                                <ReactTable
                                    onSortedChange={(newSorted: string) => {
                                        if (newSorted) {
                                            localStorage.setItem('lastBrowserSort', JSON.stringify(newSorted));
                                        }
                                        }}
                                    onFilteredChange={(filtered:boolean) => {
                                        if (filtered) {
                                            localStorage.setItem('lastBrowserFilter', JSON.stringify(filtered));
                                        } else {
                                            localStorage.removeItem('lastBrowserFilter');
                                        }
                                    }}
                                    onPageSizeChange={(pageSize:string) => {
                                        if (pageSize) {
                                            localStorage.setItem('lastBrowserPageSize', pageSize);
                                        }
                                    }}
                                    style={{clear:'right'}}
                                    data={marketplacePackagesDetails}
                                    columns={columns}
                                    loading={marketplacePackagesDetails.length === 0}
                                    SubComponent={subComponent}
                                    minRows={0}
                                    noDataText = {noDataText}
                                    defaultSorted={lastSorted ?? (isFirstParty ? MarketplacePackage.defaultFirstPartyListSorting : MarketplacePackage.defaultListSorting)}
                                    defaultFiltered={(lastFilter) ? lastFilter : undefined}
                                    defaultPageSize={lastPageSize ?? 10}
                                    className="-striped -highlight"
                                    customFilter={customFilter}
                                />
                            </div>
                        </div>
                        {error && <p>{error.message}</p>}
                    </Card>
                </div>
            </div>
        </main>
    );
}