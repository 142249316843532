import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useResizeColumns,
  useFlexLayout,
  useExpanded,
  useSortBy,
  usePagination
} from "react-table";
import classnames from "classnames";
import "./react-table.css";

// Define a default UI for filtering
function GlobalFilter({
  globalFilter,
  setGlobalFilter,
}) {
  const [value, setValue] = useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <input
      type="search"
      className="form-control"
      value={value}
      placeholder={"Search..."}
      onChange={e => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      size="64"/>
  )
}

const ReactTable = (props) => {
  const {
    className = "",
    data,
    columns,
    loading = false,
    getTdProps = () => {},
    trClassName = "",
    SubComponent,
    customFilter,
    onFilteredChange,
    onSortedChange,
    onPageSizeChange,
    defaultFiltered,
    defaultSorted,
    defaultPageSize,
    disableSortBy = false,
    hideSearch = false,
    hideHeaders = false,
    hidePagination = false
  } = props;

  const defaultColumn = {
    minWidth: 30,
    width: 50,
    maxWidth: 400
  };

  if (SubComponent) {
    columns.unshift({
      Header: "",
      id: "expander",
      resizable: false,
      className: "text-center",
      width: 30,
      Cell: ({ row }) => {
        return (
          <div
            {...row.getToggleRowExpandedProps()}
            title="Click here to see more information"
            className="rt-td rt-expandable p-0"
          >
            <div
              className={classnames(
                "rt-expander",
                row.isExpanded ? "-open" : ""
              )}
            >
              •
            </div>
          </div>
        );
      }
    });
  }

  const globalFilterFunction = useCallback((rows, columnIds, globalFilterValue) => {

    if (customFilter === null || customFilter === undefined) {
      return defaultFilter(rows, globalFilterValue);
    }

    return customFilter(rows, globalFilterValue);
  });

  const defaultFilter = (rows, globalFilterValue) => {
    const filterValues = globalFilterValue.split(',');
    const result = rows.filter(row => {
      const result = filterValues.find(fv => Object.values(row.values).find(value => value?.toLowerCase().includes(fv.trim().toLowerCase())));
      return result?.trim();
    });
    
    return result;
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { globalFilter, pageIndex, pageSize, sortBy },
    visibleColumns,
    setGlobalFilter
  } = useTable(
    { columns, data, disableSortBy: disableSortBy, defaultColumn, globalFilter: globalFilterFunction, SubComponent, initialState: { globalFilter: defaultFiltered, pageIndex: 0, pageSize: defaultPageSize, sortBy: defaultSorted } },
    useFlexLayout,
    useResizeColumns,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  );

  useEffect(() => {
    if (onSortedChange) {
      onSortedChange(sortBy);
    }
  }, [onSortedChange, sortBy]);

  useEffect(() => {
    if (onFilteredChange) {
      onFilteredChange(globalFilter);
    }
  }, [onFilteredChange, globalFilter]);

  useEffect(() => {
    if (onPageSizeChange) {
      onPageSizeChange(pageSize);
    }
  }, [onPageSizeChange, pageSize]);

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }
  
  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  return (
    <div className={classnames(className, "ReactTable")}>
      <div {...getTableProps} className="rt-table">
        {!hideSearch && <div className="rt-tr">
          <div className="rt-th" colSpan={visibleColumns.length} style={{ textAlign: 'left', }}>
            <GlobalFilter
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
          </div>
        </div>}
        {!hideHeaders && headerGroups.map((headerGroup, hgIndex) => (
          <div key={hgIndex} className={headerGroups.length > 1 && hgIndex === 0 ? "rt-thead -headerGroups" : "rt-thead -header"}>
            <div {...headerGroup.getHeaderGroupProps()} className="rt-tr">
              {headerGroup.headers.map((column) => {
                return column.resizable === false ? (
                  <div {...column.getHeaderProps(column.getSortByToggleProps())} className={column.isSorted ? column.isSortedDesc ? "rt-th -sort-desc" : "rt-th -sort-asc" : "rt-th"}>
                    {column.render("Header")}
                  </div>
                ) : (
                  <div {...column.getHeaderProps(column.getSortByToggleProps())} className={column.isSorted ? column.isSortedDesc ? "rt-th rt-resizable-header -sort-desc" : "rt-th rt-resizable-header -sort-asc" : "rt-th rt-resizable-header"}>
                    <div className="rt-resizable-header-content">
                      {column.render("Header")}
                    </div>
                    <div {...column.getResizerProps()} className="rt-resizer" />
                  </div>
                );
              })}
            </div>
          </div>
        ))}
        <div className="rt-tbody">
          <div {...getTableBodyProps()} className="rt-tr-group">
            {page.map((row, ind) => {
              prepareRow(row);
              return (
                <Fragment key={row.id}>
                  <div
                    {...row.getRowProps()}
                    className={classnames(
                      "rt-tr",
                      ind % 2 === 0 ? "-odd" : "-even",
                      trClassName
                    )}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <div
                          {...getTdProps(cell)}
                          {...cell.getCellProps()}
                          className={classnames("rt-td", cell.column.className)}
                        >
                          {cell.render("Cell")}
                        </div>
                      );
                    })}
                  </div>
                  {row.isExpanded ? SubComponent(row) : null}
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
      {!hidePagination && <div className="pagination-bottom">
        <div className="-pagination">
          <div className="-previous">
            <button type="button" disabled={!canPreviousPage} className="-btn" onClick={previousPage}>Previous</button>
          </div>
          <div className="-center">
            <span className="-pageInfo">Page <div className="-pageJump"><input aria-label="jump to page" type="number" value={pageIndex + 1} onChange={onChangeInInput} /></div> of <span className="-totalPages">{pageOptions.length}</span></span>
            <span className="select-wrap -pageSizeOptions">
              <select aria-label="rows per page" onChange={onChangeInSelect} value={pageSize}>
                <option value="5">5 rows</option>
                <option value="10">10 rows</option>
                <option value="20">20 rows</option>
                <option value="25">25 rows</option>
                <option value="50">50 rows</option>
                <option value="100">100 rows</option>
              </select>
            </span>
          </div>
          <div className="-next">
            <button type="button" disabled={!canNextPage} className="-btn" onClick={nextPage}>Next</button>
          </div>
        </div>
      </div>}
      <div className={classnames("-loading", loading ? "-active" : "")}>
        <div className="-loading-inner">Loading...</div>
      </div>
    </div>
  );
};

export default ReactTable;
